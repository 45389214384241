import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import Image from "../components/image"

const NAV = styled.nav`
    background: ${({ theme }) => theme.colors.whiteTransp2};
    z-index: 5;
    width: 100%;
    top: 3.15rem;
    position: absolute;
    ${({ theme }) => theme.media.miniphone} {
        top: 4.25rem;
        z-index: 10;
    }
    ${({ theme }) => theme.media.phone} {
        color: black;
        z-index: 5;
    }
    ${({ theme }) => theme.media.tablet} {
        top: 2.7rem;
    }
    ${({ theme }) => theme.media.desktop} {
        top: 2.7rem;
    }
`
const Menu = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    ${({ theme }) => theme.media.miniphone} {
        align-items: flex-end;
        padding: 0 1rem;
        flex-direction: row;
    }
    ${({ theme }) => theme.media.phone} {
        color: black;
    }
    ${({ theme }) => theme.media.tablet} {
        flex-direction: row;
    }
    ${({ theme }) => theme.media.desktop} {
        color: white;
    }
`

const NavLink = styled(Link)`
    color: ${({ theme }) => theme.colors.darkblue};
    font-family: "loveloblack", sans-serif;
    font-weight: ${({ theme }) => theme.font.regular};
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 3rem;
    margin: 0;
    transition: all 0.3s ease;
    :last-child {
        margin: 0 0 0 1rem;
    }
    :nth-last-child(3) {
        flex-basis: 12rem;
    }

    :hover {
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.important};
    }
    ${({ theme }) => theme.media.miniphone} {
        font-size: 0.8rem;
        text-align:center;
    }
    ${({ theme }) => theme.media.phone} {
    }
    ${({ theme }) => theme.media.tablet} {
        font-size: 0.8rem;
    }
    ${({ theme }) => theme.media.desktop} {
        font-size: 1rem;
    }
`

const A = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2rem 0 0;
    height: 3rem;
    ${({ theme }) => theme.media.miniphone} {
        display: none;
    }
    ${({ theme }) => theme.media.phone} {
        display: none;
    }
    ${({ theme }) => theme.media.tablet} {
        display: flex;
    }
    ${({ theme }) => theme.media.desktop} {
    }
`

const addClass = () => {
    return "navLink"
}

const NavHeader = () => {
    const PagesTitle = useStaticQuery(graphql`
        query {
            podroze {
                posts(where: { category: "navHeader" }, orderBy: data_ASC) {
                    author {
                        id
                    }
                    data
                    description
                    details {
                        html
                    }
                    slug
                    thumbnail
                    title
                }
            }
        }
    `)
    return (
        <NAV>
            <Menu>
                {PagesTitle.podroze.posts.map(page => (
                    <NavLink
                        key={page.slug}
                        to={`/${page.slug}/#nav`}
                        className={addClass()}
                    >
                        {/* <ImgDot imgName="dot" /> */}
                        <Image imgName="dot" />
                        {page.title}
                    </NavLink>
                ))}

                <A href="https://www.facebook.com/festpodroze" target="_blank" rel="noopener norefferer" alt="facebook link">
                    <Image imgName="dotFacebook" />
                    <Image imgName="facebook-f" />
                </A>
            </Menu>
        </NAV>
    )
}

NavHeader.propTypes = {
    siteTitle: PropTypes.string,
}

NavHeader.defaultProps = {
    siteTitle: ``,
}

export default NavHeader
