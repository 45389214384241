import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import { theme } from "../utils/theme"
import Header2 from "./header2"
import Footer from "./footer"
import "./layout.css"

import { library } from "@fortawesome/fontawesome-svg-core"
import {
    faCheckSquare,
    faCoffee,
    faAddressBook,
    faCalendarAlt,
    faHiking,
    faMugHot,
    faGuitar,
    faChurch,
    faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons"

library.add(
    faCheckSquare,
    faCoffee,
    faMugHot,
    faGuitar,
    faAddressBook,
    faCalendarAlt,
    faHiking,
    faChurch,
    faPhoneVolume
)

const GlobalStyle = createGlobalStyle`
	body {
		
		background: ${({ theme }) => theme.colors.BgColor};
		font-family: 'lato';
		font-size: 1.2rem;
		color: darkgrey;
	}
	*,*::before, *::after{
		box-sizing: border-box;
	}
`

const Layout = ({ children }) => (
    <ThemeProvider theme={theme} >
        <>
            <GlobalStyle />
            <Helmet>
                <link
                    href="https://fonts.googleapis.com/css?family=Lato|Roboto&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
            <Header2 siteTitle="Biuro podróży" />
            <main>{children}</main>
            <Footer />
            
        </>
    </ThemeProvider>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
