import React from "react"

import styled from "styled-components"
import ReactHtmlParser from "react-html-parser"


const MyContentHtml = styled.div`
    margin: 1.2rem 0 0 0;
    font-size: 90%;
    line-height: 1.4rem;
    h3 {
        color: ${({ theme }) => theme.colors.important};
        font-family: "loveloblack", sans-serif;
        margin: 2.5rem 0 1rem 0;
    }

    h4 {
        color: ${({ theme }) => theme.colors.important};
        font-size: 1.2rem;
        margin-top: 3rem;
    }

    ul {
        list-style: none;
    }

    li {
        display: block;
        width: 100%;
        justify-content: flex-start;
        position: relative;

        &::before {
            content: "\\25FE";
            position: absolute;
            color: ${({ theme }) => theme.colors.darkblue};
            font-weight: bold;
            display: inline-block;
            width: 1.2rem;
            margin-left: -1rem;
        }
    }
`

const HtmlParser = html => {
    return ReactHtmlParser(html)
}

class ContentHtml extends React.Component {
    constructor(props) {
        super(props)
        // console.log(props.dataHtml);
        this.data = props.dataHtml
    }

    render() {
        return <MyContentHtml>{HtmlParser(this.data)}</MyContentHtml>
    }
}

export default ContentHtml
