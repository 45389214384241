import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const ImgLogo = styled(Img)`
    /* margin: .5rem 0 0 0;  */
`
const Imgfacebook = styled(Img)`
    margin: 0rem 0 0 0rem;
`
const ImgDot = styled(Img)`
    margin: 0 0.7rem 0 0;
    display: block;
    transition: all 0.3s ease;
    ${".navLink"}:hover & {
        filter: grayscale(100%) invert(1) brightness(10);
    }
    ${({ theme }) => theme.media.miniphone} {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    ${({ theme }) => theme.media.phone} {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    ${({ theme }) => theme.media.tablet} {
        position: relative;
        top: 0;
        left: 0;
    }
`

const ImgDotGrey = styled(ImgDot)`
    filter: grayscale(100%) brightness(2);
    margin: 0 1.2rem;
`

const ImgDotFacebook = styled(Img)`
    margin: 0 0.6rem 0 0.6rem;
    transition: all 0.3s ease;
    ${".navLink"}:hover & {
        filter: grayscale(100%) invert(1) brightness(10);
    }
    ${({ theme }) => theme.media.miniphone} {
        position: absolute;
        top: 0;
        left: 0;
    }
    ${({ theme }) => theme.media.phone} {
        position: absolute;
        top: 0;
        left: 0;
    }
    ${({ theme }) => theme.media.tablet} {
        position: relative;
        top: 0;
        left: 0;
    }
`

const ImgStatkiFb = styled(Img)`
    /* padding: 0 1rem 0 2rem; */
    max-height: 600px;
    max-width: 315px;
    ${({ theme }) => theme.media.miniphone} {
        margin: auto;
        max-height: 300px;
    }
    ${({ theme }) => theme.media.desktop} {
        max-height: 600px;
        margin: 0 0.6rem 0 0.6rem;
    }
`

const ImgStatkiFb2 = styled(Img)`
    /* padding: 0 1rem 0 2rem; */
    max-height: 600px;
    max-width: 315px;
    ${({ theme }) => theme.media.miniphone} {
        margin: auto;
        max-height: 300px;
    }
    ${({ theme }) => theme.media.desktop} {
        max-height: 600px;
        margin: 0 0.6rem 0 0.6rem;
    }
`

const ImgSerce = styled(Img)`
    max-height: 600px;
    max-width: 200px;
    ${({ theme }) => theme.media.miniphone} {
        margin: auto;
        max-height: 100px;
    }
    ${({ theme }) => theme.media.desktop} {
        max-height: 200px;
        margin: 0 0.6rem 0 0.6rem;
    }
`

const ImgCenterFoto = styled(Img)`
    ${({ theme }) => theme.media.miniphone} {
        margin: auto;
        max-height: 80px;
        max-width: 80px;
    }
    ${({ theme }) => theme.media.desktop} {
        max-height: 160px;
        max-width: 160px;
    }
`

const ImgWinnica = styled(Img)`
    margin: 0 0.6rem 0 0.6rem;
    /* float: right; */
    padding: 0.5rem;
`

const ImgRzezby = styled(Img)`
    margin: 0 0.6rem 0 0.6rem;
    /* float: right; */
    padding: 0.5rem;
`

const getImg = (props, dataImg) => {
    if (props.imgName === "logo") {
        return <ImgLogo fluid={dataImg.logo.childImageSharp.fluid} alt="logo" />
    }

    if (props.imgName === "facebook-f") {
        return (
            <Imgfacebook
                fixed={dataImg.facebook.childImageSharp.fixed}
                alt="facebook"
            />
        )
    }
    if (props.imgName === "dot") {
        return <ImgDot fixed={dataImg.dot.childImageSharp.fixed} alt="dot" />
    }

    if (props.imgName === "dotGrey") {
        return (
            <ImgDotGrey fixed={dataImg.dot.childImageSharp.fixed} alt="dot" />
        )
    }
    if (props.imgName === "dotFacebook") {
        return (
            <ImgDotFacebook
                fixed={dataImg.dot.childImageSharp.fixed}
                alt="dotFacebook"
            />
        )
    }
    if (props.imgName === "statkiFb") {
        return (
            <ImgStatkiFb
                fluid={dataImg.statkiFb.childImageSharp.fluid}
                alt="statki"
            />
        )
    }
    if (props.imgName === "statkiFb2") {
        return (
            <ImgStatkiFb2
                fluid={dataImg.statkiFb2.childImageSharp.fluid}
                alt="statki"
            />
        )
    }
    if (props.imgName === "serce") {
        return (
            <ImgSerce
                fluid={dataImg.serce.childImageSharp.fluid}
                alt="postac_w_sercu"
            />
        )
    }
    if (props.imgName === "kawa") {
        return (
            <ImgCenterFoto
                fluid={dataImg.kawa.childImageSharp.fluid}
                alt="kawa_na_parapecie"
            />
        )
    }
    if (props.imgName === "kajak") {
        return (
            <ImgCenterFoto
                fluid={dataImg.kajak.childImageSharp.fluid}
                alt="kajaki na morzu"
            />
        )
    }
    if (props.imgName === "rzezba") {
        return (
            <ImgCenterFoto
                fluid={dataImg.rzezba.childImageSharp.fluid}
                alt="rzezba"
            />
        )
    }
    if (props.imgName === "winnica") {
        return (
            <ImgWinnica
                fluid={dataImg.winnica.childImageSharp.fluid}
                alt="winnica"
            />
        )
    }

    if (props.imgName === "rzezby") {
        return (
            <ImgRzezby
                fluid={dataImg.rzezby.childImageSharp.fluid}
                alt="rzezby"
            />
        )
    }
}

const Image = props => {
    const dataImg = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "logo2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 151, maxHeight: 82) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            dot: file(relativePath: { eq: "dot2.png" }) {
                childImageSharp {
                    fixed(width: 12, height: 12) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            facebook: file(relativePath: { eq: "facebook-f.png" }) {
                childImageSharp {
                    fixed(width: 20, height: 20) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            serce: file(relativePath: { eq: "serce.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 200, maxHeight: 200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            kawa: file(relativePath: { eq: "kawa.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 150) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            statkiFb: file(relativePath: { eq: "statkiFB.png" }) {
                childImageSharp {
                    fluid(maxHeight: 808) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            statkiFb2: file(relativePath: { eq: "jachty2.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 808) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            winnica: file(relativePath: { eq: "winnica.png" }) {
                childImageSharp {
                    fluid(maxHeight: 808) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            kajak: file(relativePath: { eq: "kajak.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 150) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            rzezba: file(relativePath: { eq: "rzezba.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 150) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return getImg(props, dataImg)
}

export default Image
