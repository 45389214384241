import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import NavHeader from "../components/navHeader"
import NavOffers from "../components/navOffers"
import { Gallery, GalleryImage } from "react-gesture-gallery"
import Image from "../components/image"
import Icon from "../img/logo.svg"

const sliderImgDir = "https://festpodroze.pl/img/slider/"

const HEADER2 = styled.header`
    /* background: ${({ theme }) => theme.colors.blue}; */
    position: relative;
    ${({ theme }) => theme.media.miniphone} {
        color: green;
    }
    ${({ theme }) => theme.media.phone} {
        color: black;
    }
    ${({ theme }) => theme.media.tablet} {
        color: blue;
    }
    ${({ theme }) => theme.media.desktop} {
        color: white;
    }
`

const LogoIcon = styled(Icon)`
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0.8rem;
    max-width: 12rem;
    min-width: 6rem;

    position: absolute;
    top: 1rem;
    left: 3%;
    z-index: 100;
    ${({ theme }) => theme.media.miniphone} {
        top: 0;
        left: 0;
        width: 90px;
        height: 50px;
        padding: 0.25rem;
    }
    ${({ theme }) => theme.media.phone} {
    }
    ${({ theme }) => theme.media.tablet} {
        top: 1rem;
        left: 3%;
        width: 166px;
        height: 100px;
    }
    ${({ theme }) => theme.media.desktop} {
        width: 166px;
        height: 100px;
        padding: 0.8rem;
    }
`

const Slider = styled.div`
    /* background: red; */
    width: 100%;
`

const GalleryWrapper = styled.div`
    position: relative;
    height: 80vh;
    ${({ theme }) => theme.media.miniphone} {
        height: 50vh;
    }
    ${({ theme }) => theme.media.desktop} {
        height: 80vh;
    }
`

const GalleryHeader = styled.div`
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    width: 100%;
`

const GalleryH1 = styled.h1`
    text-align: center;

    color: ${({ theme }) => theme.colors.white};
    font-family: "loveloblack", sans-serif;
    ${({ theme }) => theme.media.miniphone} {
        font-size: 1.7rem;
    }
    ${({ theme }) => theme.media.phone} {
    }
    ${({ theme }) => theme.media.tablet} {
    }
    ${({ theme }) => theme.media.desktop} {
        font-size: 2.25rem;
    }
    {console.log(props.src)}`
const GalleryPragraph = styled.p`
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.white};
    ${({ theme }) => theme.media.miniphone} {
        font-size: 1.2rem;
        text-align: center;
    }
    ${({ theme }) => theme.media.phone} {
    }
    ${({ theme }) => theme.media.tablet} {
    }
    ${({ theme }) => theme.media.desktop} {
    }
`

const Afb = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1px;
    right: 0;    
    width: 50px;
    height: 50px;
    margin: 0;
    z-index: 20;
    background-color: white;
    ${({ theme }) => theme.media.miniphone} {
        display: flex;
    }
    ${({ theme }) => theme.media.phone} {
        display: flex;
    }
    ${({ theme }) => theme.media.tablet} {
        display: none;
    }
    ${({ theme }) => theme.media.desktop} {
    }
`

const MyGalleryImage = props => {
    return (
        <GalleryWrapper>
            <GalleryHeader>
                <GalleryH1>{props.title}</GalleryH1>
                <GalleryPragraph>{props.text}</GalleryPragraph>
            </GalleryHeader>
            <GalleryImage key={props.mykey} objectFit="cover" src={props.src} />
        </GalleryWrapper>
    )
}

const Header2 = () => {
    const data = useStaticQuery(graphql`
        query {
            podroze {
                sliders {
                    id
                    imageDesktop
                    imageTablet
                    imagePhone
                    text
                    title
                }
            }
        }
    `)

    const sliders = data.podroze.sliders

    const [index, setIndex] = React.useState(0)

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (index === sliders.length - 1) {
                setIndex(0)
            } else {
                setIndex(index + 1)
            }
        }, 100000)

        return () => clearInterval(interval)
    }, [index])

    return (
        <HEADER2>
            <Link to="/">
                <LogoIcon />
            </Link>
            <Afb href="www.facebook.com/festpodroze">
                <Image imgName="facebook-f" />
            </Afb>
            <NavHeader />
            <Slider>
                <Gallery
                    style={
                        {
                            // background: "pink",
                        }
                    }
                    index={index}
                    onRequestChange={i => {
                        setIndex(i)
                    }}
                >
                    {sliders.map((slide, idx) => (
                        <MyGalleryImage
                            key={slide.id}
                            mykey={slide.id}
                            src={sliderImgDir + slide.imageDesktop}
                            idx={index}
                            title={slide.title}
                            text={slide.text}
                        />
                    ))}
                </Gallery>
            </Slider>

            <NavOffers />
        </HEADER2>
    )
}
Header2.propTypes = {
    siteTitle: PropTypes.string,
}

Header2.defaultProps = {
    siteTitle: ``,
}

export default Header2
