const colors = {
    white: "rgb(255, 255, 255)",
    whiteTransp1: "rgba(250, 250, 250, 0.2)",
    whiteTransp2: "rgba(255, 255, 255, 0.8)",
    BgColor: "#FFFFFF",
    BgColorPost: "#deeef3",
    BgColorPost1: "#D0F5FF",
    LineColor: "#bec0cc",
    blue: "#009afc",
    primary: "rgba(0, 0, 0, 0.6)",
    secondary: "rgba(28, 199, 212, 0.95)",
    dark: "#484D6D",
    navHeaderBg: "rgba(0, 0, 0, 0.6)",
    navOffersBg: "rgba(0, 0, 0, 0.9)",
    red: "rgba(242, 87, 87, 0.8)",
    important: "#fa0a64",
    darkblue: "#0055bb",
    darkblueT: "rgba(0, 85, 187, 0.7)",
    lightblue: "#00afff",
    lightblueT: "rgba(0,175,255,0.1)",
    darkgrey: "#353531",
}

export default colors
