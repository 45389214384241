import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
// import ReactHtmlParser from "react-html-parser"
import BtnClose from "../components/btnClose"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentHtml from "../components/contentHtml"
import ContactForm from "../components/contactForm"
import Gallery from "../components/lightbox"

const postImgDir = "https://festpodroze.pl/img/posts/"

const ContentPost = styled.div`
    margin: auto;
    padding: 2rem 2rem 2rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;
    ${({ theme }) => theme.media.miniphone} {
        margin: 0 auto;
        order: 1;
        font-size: 1.5rem;
        padding: 2rem 0 2rem 0;
        justify-content: center;
    }
    ${({ theme }) => theme.media.phone} {
        font-size: 1.5rem;
    }
    ${({ theme }) => theme.media.tablet} {
        width: 90%;
        font-size: 1.5rem;
    }
    ${({ theme }) => theme.media.desktop} {
        width: 90%;

        margin: auto;
        font-size: 1.5rem;
    }
`

const Header = styled.header`
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    margin: 0 0 1.5rem 0;
    ${({ theme }) => theme.media.miniphone} {
        order: 1;
        font-size: 1.5rem;
    }
    ${({ theme }) => theme.media.phone} {
        font-size: 1.5rem;
    }
    ${({ theme }) => theme.media.tablet} {
        font-size: 1.5rem;
    }
    ${({ theme }) => theme.media.desktop} {
        font-size: 1.5rem;
    }
`

const H1 = styled.h1`
    font-family: "loveloblack", sans-serif;
    color: ${({ theme }) => theme.colors.important};
    margin: 0;
    ${({ theme }) => theme.media.miniphone} {
        margin: 1rem;
        font-size: 1.6rem;
    }
    ${({ theme }) => theme.media.phone} {
    }
    ${({ theme }) => theme.media.tablet} {
    }
    ${({ theme }) => theme.media.desktop} {
        margin: 1rem 1rem 1rem 1rem;
        font-size: 2rem;
    }
`

const ImgPost = styled.img`
    display: inline-block;
    width: 100%;
    margin: 0 auto;
`

const BlockText = styled.div`
    flex-basis: 75%;
    padding: 0 0 0 5rem;
    border-color: ${({ theme }) => theme.colors.LineColor};
    border-left: 1px solid ${({ theme }) => theme.colors.LineColor};
    font-size: 1rem;
    ${({ theme }) => theme.media.miniphone} {
        flex-basis: 90%;
        order: 2;
        font-size: 1rem;
        border: 0;
        padding: 0;
    }
    ${({ theme }) => theme.media.phone} {
    }
    ${({ theme }) => theme.media.tablet} {
    }
    ${({ theme }) => theme.media.desktop} {
        flex-basis: 75%;
        padding: 0 0 0 5rem;
        border-color: ${({ theme }) => theme.colors.LineColor};
        border-left: 1px solid ${({ theme }) => theme.colors.LineColor};
    }
`
const Description = styled.p`
    color: ${({ theme }) => theme.colors.darkgrey};
    font-size: 95%;
`

const H3 = styled.h3`
    margin: 4.5rem 0 1rem 0;
    color: ${({ theme }) => theme.colors.important};
    font-family: "loveloblack", sans-serif;
    ${({ theme }) => theme.media.miniphone} {
        margin: 3rem 0 1rem;
    }
`

const H4 = styled.h4`
    margin: 4rem 0 1rem 0;
    color: ${({ theme }) => theme.colors.important};
    font-family: "loveloblack", sans-serif;
    font-size: 0.9rem;
`

const IsGallery = props => {
    if (!props.gallery) {
        return null
    }
    let photos = Object.values(props.gallery).map(img => postImgDir + img)
    return <Gallery gallery={photos} />
}

const Departure = styled.p`
    text-transform: uppercase;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.darkblue};
    display: ${props =>
        props.isDisplay === null ||
        props.isDisplay === " " ||
        props.isDisplay === ""
            ? "none"
            : "block"};
`

const Price = styled.p`
    text-transform: uppercase;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.darkblue};
    display: ${props =>
        props.isDisplay !== null &&
        props.isDisplay !== " " &&
        props.isDisplay !== ""
            ? "block"
            : "none"};
`
const BlockPhoto = styled.div`
    width: 25%;
    padding: 0 2rem;
    ${({ theme }) => theme.media.miniphone} {
        margin: 1rem;
        padding: 0;
        order: 3;
        font-size: 1.5rem;
        width: 95%;
    }
    ${({ theme }) => theme.media.phone} {
    }
    ${({ theme }) => theme.media.tablet} {
        width: 23%;
    }
    ${({ theme }) => theme.media.desktop} {
        order: 2;
        margin: 0 0.5rem;
        width: 21%;
        padding: 0 0.4rem 0 0;
    }
`

class postTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.data = props.pageContext.data
    }

    state = { isVisible: true }

    render() {
        return (
            <Layout>
                <SEO title="Home" />
                <ContentPost>
                    <Header>
                        <H1>{this.data.title}</H1>
                        <BtnClose />
                    </Header>

                    <BlockPhoto>
                        <ImgPost
                            alt={`zdjecie-miejsca-podrozy ${this.data.thumbnail} `}
                            src={postImgDir + this.data.thumbnail}
                        />
                        <H4>galeria</H4>
                        <IsGallery gallery={this.data.gallery} />
                    </BlockPhoto>
                    <BlockText>
                        <Description>{this.data.description}</Description>
                        <Departure isDisplay={this.data.departure}>
                            <span>Termin: </span> {this.data.departure}
                        </Departure>
                        <Price isDisplay={this.data.price}>
                            <span>Cena: </span> {this.data.price}
                        </Price>

                        <ContentHtml dataHtml={this.data.details.html} />

                        <H3 id="contactForm">Zapytaj o ofertę / Rezerwuj</H3>
                        <p>kontakt mailowy: biuro@festpodroze.pl </p>
                        <p>lub przez poniższy formularz kontaktowy:</p>
                        <ContactForm />
                    </BlockText>
                </ContentPost>
            </Layout>
        )
    }
}

postTemplate.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    description: PropTypes.string,
    html: PropTypes.string,
}

postTemplate.defaultProps = {
    title: ``,
    icon: ``,
    description: ``,
    html: ``,
}

export default postTemplate
