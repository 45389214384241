import colors from "./color"

export const theme = {
    colors,
    font: {
        thin: 300,
        regular: 400,
        bold: 700,
    },
    media: {
        miniphone: "@media (min-width: 300px)",
        phone: "@media (min-width: 410px)",
        tablet: "@media (min-width: 728px )",
        desktop: "@media (min-width: 1024px )",
	},
}

