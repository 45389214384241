import React, { Component } from "react"
import styled from "styled-components"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css" // This only needs to be imported once in your app


const PhotoContainer = styled.div`
    flex-basis: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    flex-direction: column;
`

const ImgGallery = styled.img`
    width: 100%;
    margin: 0.1rem;
    transition: all 0.5s ease;
    :hover {
        filter: brightness(1.2);
        transform: scale(1.05);
        cursor: pointer;
    }
`

class Gallery extends Component {
    constructor(props) {
        super(props)
        this.images = props.gallery.filter(data => data != null)
        this.state = {
            photoIndex: 0,
            isOpen: false,
        }
    }

    getIdx(e, idx) {
        this.setState({ isOpen: true, photoIndex: idx })
    }

    render() {
        const { photoIndex, isOpen } = this.state

        return (
            <div>
                <PhotoContainer>
                    {Object.values(this.images).map((src, idx) =>
                        src != null ? (
                            <ImgGallery
                                key={idx}
                                src={src}
                                alt="photo"
                                data-idx={idx}
                                onClick={e => this.getIdx(e, idx)}
                            />
                        ) : null
                    )}
                </PhotoContainer>

                {isOpen && (
                    <Lightbox
                        mainSrc={this.images[photoIndex]}
                        nextSrc={this.images[+1 % this.images.length]}
                        prevSrc={
                            this.images[
                                (photoIndex + this.images.length - 1) %
                                    this.images.length
                            ]
                        }
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex:
                                    (photoIndex + this.images.length - 1) %
                                    this.images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex:
                                    (photoIndex + 1) % this.images.length,
                            })
                        }
                    />
                )}
            </div>
        )
    }
}

export default Gallery
