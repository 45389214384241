import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import {
    faCalendarAlt,
    faSkiing,
    faWater,
    faShuttleVan,
    faChurch,
    faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const NAV = styled.nav`
    /* background: ${({ theme }) => theme.colors.lightblue}; */
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    flex-basis: 100%;
    /* height: 14vh; */
    margin: auto 0 0 0;
    ${({ theme }) => theme.media.miniphone} {
        flex-wrap: wrap;
    }
    ${({ theme }) => theme.media.phone} {
        flex-wrap: wrap;
    }
    ${({ theme }) => theme.media.tablet} {
        flex-wrap: nowrap;
    }
`
const NavLink = styled(Link)`
    position: relative;
    color: ${({ theme }) => theme.colors.lightblue};
    text-transform: uppercase;
    font-size: 1rem;
    text-decoration: none;
    background: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 7rem;
    flex-basis: 100%;
    margin: 0;
    transition: all 0.5s ease;
    :after {
        content: "";
        border-right: 1px solid ${({ theme }) => theme.colors.lightblue};
        position: absolute;
        top: 30px;
        bottom: 60px;
        right: 0px;
    }
    :hover {
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.important};
    }
    :last-child {
        margin: 0;
        border-right: 0;
    }
    :last-child {
        margin: 0;
        border-right: 0;
        :after {
            content: "";
            border-right: 0;
        }
    }
    ${({ theme }) => theme.media.miniphone} {
        flex-wrap: nowrap;
        flex-direction: row;
        height: 4rem;
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightblueT};
    }
    ${({ theme }) => theme.media.phone} {
        flex-wrap: nowrap;
        flex-direction: row;
    }
    ${({ theme }) => theme.media.tablet} {
        flex-wrap: wrap;
        flex-direction: column;
        text-align: center;
        font-size: 0.8rem;
        height: 6rem;
        border-bottom: 0;
    }
    ${({ theme }) => theme.media.desktop} {
        flex-wrap: wrap;
        flex-direction: column;
        font-size: 1rem;
        height: 7rem;
    }
`

const MyFontAwesomeIcon = styled(FontAwesomeIcon)`
    font-size: 1.8rem;
    margin: 1rem 0;
    ${({ theme }) => theme.media.miniphone} {
        margin: 0 1rem 0 0;
        font-size: 1rem;
    }
    ${({ theme }) => theme.media.phone} {
        margin: 0 1rem 0 0;
        font-size: 1rem;
    }
    ${({ theme }) => theme.media.tablet} {
        text-align: center;
        font-size: 1.3rem;
        margin: 0 0 1rem 0;
    }
    ${({ theme }) => theme.media.desktop} {
        margin: 1rem 0;
        font-size: 1.8rem;
    }
`

const NavOffers = () => (
    <NAV id="nav">
        <NavLink key={1} to={"/wolne-terminy#nav"}>
            <MyFontAwesomeIcon icon={faCalendarAlt} />
            wolne miejsca
        </NavLink>
        <NavLink key={2} to={"/aktywne-wyjazdy#nav"}>
            <MyFontAwesomeIcon icon={faSkiing} />
            aktywne wyjazdy
        </NavLink>
        <NavLink key={3} to={"/wypoczynek-i-relaks#nav"}>
            <MyFontAwesomeIcon icon={faWater} />
            wypoczynek i relaks
        </NavLink>
        <NavLink key={4} to={"/swieta-i-festiwale#nav"}>
            <MyFontAwesomeIcon icon={faShuttleVan} />
            wycieczki i festiwale
        </NavLink>
        <NavLink key={5} to={"/pielgrzymki#nav"}>
            <MyFontAwesomeIcon icon={faChurch} />
            pielgrzymki
        </NavLink>
        <NavLink key={6} to={"/na-zamowienie#nav"}>
            <MyFontAwesomeIcon icon={faPhoneVolume} />
            na zamówienie
        </NavLink>
    </NAV>
)

NavOffers.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
}

NavOffers.defaultProps = {
    title: ``,
    icon: ``,
}

export default NavOffers
