import React from "react"
import styled from "styled-components"
// import PropTypes from "prop-types"

const ContentFooter = styled.footer`
    margin: 0;
    background-color: ${({ theme }) => theme.colors.important};
    color: #ffffff;
    margin: 3rem 0 0 0;
    padding: 0.5rem;
    p {
        margin: 0;
        font-size: 0.8rem;
        padding: 0.2rem;
        text-align: right;
    }
`

const Footer = () => (
    <ContentFooter>
        <p>
            Biuro podróży: "Fest Podróże" ©{" "}
            {new Date().getFullYear()}
        </p>
    </ContentFooter>
)

export default Footer
